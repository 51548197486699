export var addData = {
  createUserId: '',
  createDate: null,
  modifyDate: null,
  roleCode: '',
  roleDesc: '',
  enterpriseCode: '',
  isHas: null
}
export var rolemenudata = {
  createUserId: null,
  createDate: null,
  modifyDate: null,
  label: '',
  alias: '',
  menuUrl: null,
  menuType: null,
  openWay: null,
  openView: '',
  parentId: null,
  defaultOpenAlias: '',
  defaultSelectId: '',
  menuOrder: null,
  menuCode: null,
  enterpriseCode: null
}

export var form = {
  name: '',
  region: '',
  date1: '',
  date2: '',
  delivery: false,
  type: [],
  resource: '',
  desc: ''
}
export var menudata = [{
  label: '+新建角色',
  id: 1
}]
export var navData = [{
  label: '管理员角色',
  name: 'adminRole',
  id: 1
}, {
  label: '供应商角色',
  name: 'supplierRole',
  id: 2
}]
