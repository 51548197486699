<template>
    <div>
      <table-tab :TabData="navData" @tabSwitch="onSwitch" defaultData="adminRole"></table-tab>
      <list-menu @buttonClick="addRole" :menuData="menudata"></list-menu>
      <dialog-box ref="roleDialogbox" :dialogShow="dialogShow" :showData = "showData" :roleType = "roleType" :componentName="componentBody"
          @handleClose='handleClose' :title="title" :showCheckbox="showCheckbox"
          :buttonData="buttonData" @saveDataPermission="saveDataPermission" @savePermission="savePermission" @addrole = "addRoled"></dialog-box>
      <div class="tableList">
        <div class="table">
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="角色ID"></el-table-column>
            <el-table-column prop="roleCode" label="角色名称"></el-table-column>
            <el-table-column prop="roleDesc" label="角色描述"> </el-table-column>
            <el-table-column prop="" label="操作">
              <template #default="scope">
                <el-button size="mini"  type="text"  @click="permissionView(scope.$index, scope.row)">查看权限</el-button>
                <el-button  size="mini"  type="text"  @click="changeRole(scope.$index, scope.row)">修改</el-button>
                <el-button size="mini" type="text" @click="deleterole(scope.$index, scope.row)">删除</el-button>
                <el-button size="mini" type="text" @click="settingPermission(scope.$index, scope.row)">分配菜单权限</el-button>
                <el-button size="mini" type="text" @click="settingDataPermission(scope.$index, scope.row)">菜单数据权限</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="tablepage" v-if="tableData&&tableData.length>0">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size= pageSize
              layout="total, prev, pager, next, jumper"
              :total= total>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { addData, rolemenudata, form, menudata, navData } from './js/roledata.js'
import { request, requestForm } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
export default {
  name: 'RolemanageMent',
  components: Component.components,
  data () {
    request('/api/system/menu/treeList', 'post').then((response) => {
      this.treedata = response.data
      var arraydata = []
      this.rolemenudata = this.recursion(response.data, arraydata)
    })
    return {
      dialogBody: '',
      tableData: [],
      form: form,
      treedata: [],
      addData: { ...addData },
      rolemenudata: { ...rolemenudata },
      menudata: menudata,
      pageNum: 1,
      navData: navData,
      pageSize: 10,
      roleType: 1,
      total: 100,
      menuIdList: [],
      roleId: '',
      showData: [],
      dialogShow: false,
      componentBody: '',
      title: '',
      showCheckbox: false,
      buttonData: []
    }
  },
  created () {
    this.GetTableData()
  },
  methods: {
    onSwitch: function (data) {
      if (data.props.name === 'supplierRole') {
        this.roleType = 2
      }
      if (data.props.name === 'adminRole') {
        this.roleType = 1
      }
      this.GetTableData()
    },
    handleNodeClick (data) {
      this.addData = { ...data }
    },
    handleClose: function () {
      this.dialogShow = false
    },
    changeRole: function (index, data) {
      this.showData = data
      this.dialogShow = true
      this.componentBody = 'RoleDialogBody'
      this.title = '修改角色'
      this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'addrole', size: 'mini', type: 'primary' }]
    },
    addRole: function (data) {
      this.showData = undefined
      this.dialogShow = true
      this.componentBody = 'RoleDialogBody'
      this.title = '新建角色'
      this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'addrole', size: 'mini', type: 'primary' }]
    },
    addRoled: function (data) {
      request('/api/system/role/save', 'post', this.$refs.roleDialogbox.$refs.content.form).then((response) => {
        if (response.data === true) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: '操作失败',
            type: 'error'
          })
        }
      })
      this.GetTableData()
      this.dialogShow = false
    },
    permissionView (index, rowData) {
      request('/api/system/role/getMenuByRole?roleId=' + rowData.id, 'post').then((response) => {
        this.recursion(response.data)
        this.showData = response.data
        this.dialogShow = true
        this.showCheckbox = false
        this.componentBody = 'AuthDialogBody'
        this.title = '查看权限'
        this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'handleClose', size: 'mini', type: 'primary' }]
      })
    },
    // 获取 分页查询列表
    GetTableData (data) {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        isSupplierRole: this.roleType
      }
      requestForm('/api/system/role/list', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    // 点击下一步分配权限 保存新增的角色
    saveaddrole () {
      request('/api/system/role/save', 'post', this.addData).then((response) => {
        if (response.data === true) {
          this.GetTableData()
          this.$message({
            message: '下一步分配权限',
            type: 'success'
          })
        } else {
          this.$message({
            message: '操作失败',
            type: 'error'
          })
        }
      })
    },
    deleterole (index, data) {
      var deleteData = [data.id]
      this.$confirm('此操作将删除记录,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/system/role/delete', 'delete', deleteData).then((response) => {
          if (response.code === '200') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.GetTableData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    savePermission: function () {
      const keys = this.$refs.roleDialogbox.$refs.content.chenkKeys
      request('/api/system/role/saveRoleMenu?roleId=' + this.roleId, 'post', keys).then((response) => {
        if (response.data === true) {
          this.$message({
            message: '分配成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: '操作失败',
            type: 'error'
          })
        }
        this.GetTableData()
        this.dialogShow = false
      })
    },
    saveDataPermission: function () {
      const keys = this.$refs.roleDialogbox.$refs.content.checkedNodes
      request('/api/system/menu/saveRoleMenuData?roleId=' + this.roleId, 'post', keys).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '分配成功',
            type: 'success'
          })
        }
        this.dialogShow = false
        this.GetTableData()
      })
    },
    // 给角色赋予权限
    settingPermission (index, rowData) {
      this.roleId = rowData.id
      request('/api/system/menu/treeList', 'post').then((response) => {
        if (response.code === '200') {
          this.showData = response.data
          this.dialogShow = true
          this.componentBody = 'AuthDialogBody'
          this.title = '分配权限'
          this.showCheckbox = true
          this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'savePermission', size: 'mini', type: 'primary' }]
          request('/api/system/role/getMenuList?roleId=' + this.roleId, 'post').then((response) => {
            if (response.code === '200') {
              this.$refs.roleDialogbox.$refs.content.setCheck(response.data)
            }
          })
        }
      })
    },
    // 给角色赋予权限
    settingDataPermission (index, rowData) {
      this.roleId = rowData.id
      request('/api/system/menu/getMenuData', 'post').then((response) => {
        if (response.code === '200') {
          this.showData = response.data
          this.dialogShow = true
          this.componentBody = 'AuthDialogBody'
          this.title = '分配权限'
          this.showCheckbox = true
          this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'saveDataPermission', size: 'mini', type: 'primary' }]
          request('/api/system/menu/getRoleMenuData?roleId=' + this.roleId, 'post').then((response) => {
            if (response.code === '200') {
              this.$refs.roleDialogbox.$refs.content.setCheck(response.data)
            }
          })
        }
      })
    },
    recursion: function (data) {
      for (var node = 0; node < data.length; node++) {
        if (data[node].isHas !== 0) {
          data.splice(node, 1)
          node = node - 1
        } else {
          if (data[node].children.length > 0) {
            this.recursion(data[node].children)
          }
        }
      }
      return data
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.GetTableData()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.GetTableData()
    }

  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
</style>
